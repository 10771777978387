// Used for a lighter alternative to Modernizr

// Based on https://developers.google.com/speed/webp/faq#how_can_i_detect_browser_support_for_webp
export function checkForWebp() {
    const img = new Image();
    img.src =
        'data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA';
    img.onload = function () {
        document.body.classList.add(
            img.width > 0 && img.height > 0 ? 'has-webp' : 'no-webp'
        );
    };
    img.onerror = function () {
        callback(feature, false);
    };
}

export function checkAndShimPicture() {
    const hasPicture = 'HTMLPictureElement' in window;
    document.body.classList.add(
        hasPicture ? 'has-picture' : 'no-picture'
    );
    if (!hasPicture) {
        // HTML5 shiv, no polyfill since the fallback will be img
        document.createElement('picture');
        document.createElement('source');
    }
}
