// import "vite/dynamic-import-polyfill";

import { setupNetworkSlider } from './network-slider';
window.addEventListener('slick-ready', () => {
    setupNetworkSlider();
});

import { checkAndShimPicture, checkForWebp } from '../../_shared/feature-check';
checkAndShimPicture();
checkForWebp();
import { loadAutocompleteAndClient } from '../../_shared/vendor/algolia';
import { getStartingDate } from "../../_shared/utils/utils";
import { COUNT_NAME_MAPPING } from "../../_shared/constants";

import '../../_shared/search';
import '../../_shared/popups/lead_popup_post_requirements';

import '../../_shared/global-pass.css';
import '../../_shared/flexible-spaces';
import './home.css';
import './network-slider.css';

let searchSuggestion = null;
let workspaceSearchType = '';
window.Page = {};
Page.baseUrl = $('#base_url').val();
Page.cloudFrontUrl = $('#cloudfront_url').val();

Page.filters = [];
Page.filters.capacity = 0;
Page.filters.resourceTypes = 'all';
Page.returnUrl = '';
Page.filters.duration = false;
Page.filters.duration_metrics = false;
Page.filters.start_date = false;
getResourceTypeFilters();

$(function () {
    $('#capacity_select li').on('click', function () {
        Page.filters.capacity = $(this).attr('rel');
    });
});
requestAnimationFrame(() => {
    loadSearchAutocompleteMenu('search_bar');
    loadSearchAutocompleteMenu('navbar-search-input');
});

window.change_option = function change_option(ipt) {
    var ipt_id = ipt.id;
    if ( typeof( testSearchPage ) !== "undefined" ) {
        ipt_id = ipt.value;
    }

    var ipts = ['private_office', 'desks', 'meeting_room', 'virtual_office', 'airport_lounge'];
    for (var nv = 0; nv < ipts.length; nv++) {
        if (
            ipt_id != 'ipt_' + ipts[nv] &&
            $('#ipt_' + ipts[nv]).is(':checked')
        ) {
            $('#ipt_' + ipts[nv]).prop('checked', false);
        }
    }

    workspaceSearchType = ipt.value;
    storeResourceTypeFilters();
    renderAppropriateDurationOptions(ipt_id);
};

function renderAppropriateDurationOptions( type ) {
    let durationSelect = $('[name="search_form_duration"]');
    let durationMetricsSelect = $('[name="search_form_duration_metrics"]');
    console.log(durationSelect);
    console.log(durationMetricsSelect);
}

function storeResourceTypeFilters() {
    var values = [];
    $('[name="resource_types"]:checked').each( (index, el) => {
        values.push( $(el).val() );
    } )
    window.localStorage.setItem( 'resourceTypeFilters', JSON.stringify(values) );
}

function getResourceTypeFilters() {
    var items = JSON.parse( window.localStorage.getItem( 'resourceTypeFilters' ) );
    if ( items === null ) {
        // this has to be on production and staging
        return;
    }
    $('[name="resource_types"]').each( (index, el) => {
        if ( items.indexOf( $(el).val() ) === -1 ) {
            $(el).prop('checked', false);
        }
    } );
}

window.search_option = function search_option() {
    var search = $('#search_bar').val().trim();
    var duration = $('#ipt_duration').val().trim();
    $('#search_error').hide();
    $('#duration_error').hide();
    $('#pax_error').hide();
    if (search == '') {
        $('#search_error').show();
    }
    if (duration == '') {
        $('#duration_error').show();
    }
};

// Does not use header's auto complete menu, clicking on a suggestion should not navigate
async function loadSearchAutocompleteMenu(searchBoxInputId) {
    const { autocomplete, client } = await loadAutocompleteAndClient();

    const spaces = client.initIndex($('#algolia_space_index').val());
    const cities = client.initIndex($('#algolia_city_index').val());
    const countries = client.initIndex($('#algolia_country_index').val());

    $('#' + searchBoxInputId)
        .autocomplete(
            {
                debug: true,
                autoselect: true,
                templates: {
                    dropdownMenu:
                        '<div class="aa-dataset-cities"></div>' +
                        '<div class="aa-dataset-spaces"></div>' +
                        '<div class="aa-dataset-countries"></div>',
                },
            },
            [
                {
                    source: autocomplete.sources.hits(spaces, {
                        hitsPerPage: 10,
                        filters: '',
                    }),
                    displayKey: 'display_name',
                    name: 'spaces',
                    templates: {
                        suggestion: function (suggestion) {
                            if (suggestion.display_name == null) return null;
                            if (suggestion.is_closed == 1) return (
                                '<span class="fa fa-buildingui closed-space"><span class="cw_font">' +
                                suggestion._highlightResult.display_name.value +
                                '</span></span>'
                            );
                            return (
                                '<span class="fa fa-buildingui"><span class="cw_font">' +
                                suggestion._highlightResult.display_name.value +
                                '</span></span>'
                            );
                        },
                    },
                },
                {
                    source: autocomplete.sources.hits(cities, {
                        hitsPerPage: 4,
                    }),
                    displayKey: 'display_name',
                    name: 'cities',
                    templates: {
                        suggestion: function (suggestion) {
                            return (
                                '<div>' +
                                '<span class="fa fa-map-markerui"><span class="cw_font">' +
                                suggestion._highlightResult.display_name.value +
                                '</span></span>' +
                                '</div>' +
                                '<div class="space_count">' +
                                '(<em>' +
                                suggestion._highlightResult.total_spaces.value +
                                '</em> spaces)' +
                                '</div>'
                            );
                        },
                    },
                },
                {
                    source: autocomplete.sources.hits(countries, {
                        hitsPerPage: 2,
                    }),
                    displayKey: 'display_name',
                    name: 'countries',
                    templates: {
                        suggestion: function (suggestion) {
                            return (
                                '<div>' +
                                '<span class="fa fa-globeui"><span class="cw_font">' +
                                suggestion._highlightResult.display_name.value +
                                '</span></span>' +
                                '</div>' +
                                '<div class="space_count">' +
                                '(<em>' +
                                suggestion._highlightResult.total_spaces.value +
                                '</em> spaces)' +
                                '</div>'
                            );
                        },
                    },
                },
            ]
        )
        .on('autocomplete:selected', function (event, suggestion, dataset) {
            Page.filters.resourceTypes = [];
            searchSuggestion = suggestion;
            $.each($('input[name="resource_types"]:checked'), function () {
                Page.filters.resourceTypes.push($(this).val());
            });
            let queryString = '?filter_resource_types=' + Page.filters.resourceTypes;
            if (Page.filters.resourceTypes.length == 0) {
                queryString = '';
            }

            if (dataset == 'spaces') {
                Page.returnUrl = Page.baseUrl + suggestion.profile_page_url;
                // console.log(Page.returnUrl);
            } else if (dataset == 'cities') {
                Page.returnUrl =
                    Page.baseUrl + suggestion.search_page_url + queryString;
                // console.log(Page.returnUrl);
            } else if (dataset == 'countries') {
                Page.returnUrl =
                    Page.baseUrl + suggestion.search_page_url + queryString;
            } else {
                console.log('Not Found');
            }

            //TODO remove testSearchPage after the implementation of new search is done and approved
            if ( typeof( testSearchPage ) !== "undefined" ) {
                //fill in the selected data into page variable
                let formElement = document.forms.searchForm;
                const queryString = new URLSearchParams(new FormData(formElement)).toString();
            } else {
                let endpoint = Page.returnUrl.replace('/search/', '/');
                window.location.href = endpoint;
            }

        }).on('autocomplete:updated', function(event, suggestion, dataset) {
            if ($('.aa-dataset-spaces .aa-suggestion').length > 1) {
                $(".closed-space").parent().css( "display", "none" );
            }
            $('.aa-dataset-spaces .aa-suggestion:gt(5)').hide();
        });

    $(document).on('click', function () {
        $('.aa-dropdown-menu').hide();
    });
}

// const mainHeader = document.querySelector('main header');
// const navSearchToggle = document.querySelector('.js-nav-search-toggle');
// mainHeader.style.height = 'auto';
// function toggleNavSearch(homeSearchVisible) {
//   if (homeSearchVisible) {
//     navSearchToggle.style.maxHeight = 0;
//     navSearchToggle.style.visibility = 'hidden';
//     navSearchToggle.style.opacity = 0;
//     $('#navBarSearchContainer .aa-dropdown-menu').hide();
//   } else {
//     navSearchToggle.style.maxHeight = '100px';
//     navSearchToggle.style.visibility = 'visible';
//     navSearchToggle.style.opacity = 1;
//   }
// }

// const searchBarViewObserver = new IntersectionObserver(
//     (entries) => {
//         entries.forEach((entry) => {
//             toggleNavSearch(entry.isIntersecting);
//         });
//     },
//     { threshold: 1.0, root: null, rootMargin: '0px 0px 50px 0px' }
// );
// searchBarViewObserver.observe(document.querySelector('#search_bar'));

if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    // true for mobile device
    var a = document.querySelector('.js-nav-search-toggle');
    var b = document.querySelector('.js-logo-mobile-con');
    b.after(a);
}else{
    // false for not mobile device

}

function checkHomeSearchValidation() {
  function showInputErrorMessage(inputSelector, textPosition) {
    $(`${inputSelector}`).css('border', '1px solid #e22b28');
    $(`.test-search #validation-err:${textPosition}`).css('display', 'block');
    setTimeout(() => {
      $(`${inputSelector}`).css('border', '1px solid #cad2d0');
      $(`.test-search #validation-err:${textPosition}`).removeAttr('style');
    }, 4000);
  }

  const whereInvalid = !$('#search_bar').val() || $('#search_bar').is(':invalid');
  const lookingForInvalid = $('#office_type .unselect-text').length;

  if (whereInvalid || lookingForInvalid) {
    if (whereInvalid) {
      showInputErrorMessage('#search_bar', 'first');
    }
    if (lookingForInvalid) {
      showInputErrorMessage('#office_type .select-styled', 'last');
    }
    return false;
  }
  return true;
}

$('#searchFormSubmit').on('click', async (e) => {
    e.preventDefault();

    if (!checkHomeSearchValidation()) return;

    const today = new Date();
    const { dd, mm, yyyy } = getStartingDate(today.setDate(today.getDate() + 1));
    const dataValue = $('#start_date').val();

    const request = {
      search_form_office_type: workspaceSearchType || 'all',
      search_form_start_date: dataValue || `${dd}/${mm}/${yyyy}`,
    };

    if (!searchSuggestion?.search_page_url) {
      $.get("https://ipinfo.io", () => {}, "jsonp").always(function (resp) {
            const country = resp ? `${COUNT_NAME_MAPPING[resp.country]}/${resp.city}` : '';

            window.location.href = `${Page.baseUrl}search/${country}?${new URLSearchParams(request).toString()}`;
      });
    } else {
      window.location.href = `${Page.baseUrl}${searchSuggestion?.search_page_url}?${new URLSearchParams(request).toString()}`;
    }

    //? if no #search_bar value required
    // const isSearchBarValue = document.getElementById('search_bar')?.value;
    // if (!isSearchBarValue) {
    //     $.get("https://ipinfo.io", function () {
    //     }, "jsonp").always(function (resp) {
    //         const country = resp ? `${COUNT_NAME_MAPPING[resp.country]}/${resp.city}` : '';
    //         const defaultRequest = {
    //             search_form_office_type: 'desks',
    //             search_form_start_date: `${dd}/${mm}/${yyyy}`,
    //         };
    //         window.location.href = `${Page.baseUrl}search/${country}?${new URLSearchParams(defaultRequest).toString()}`;
    //     });
    // } else {
    //     window.location.href = `${Page.baseUrl}${searchSuggestion?.search_page_url}?${new URLSearchParams(new FormData(document.forms.searchForm)).toString()}`;
    // }
});

$('#searchFormClear').on('click', (e) => {
    e.preventDefault();
    $('#office_type .select-styled').text($('#office_type option:first').text());
    $('#start_date').val('');
    $('#duration .select-styled').text($('#duration option:first').text());
    $('#duration_metrics .select-styled').text($('#duration_metrics option[value=day]').text());
    $('#search_bar').val('');
    $('#capacity .select-styled').text($('#capacity option:first').text());
});


