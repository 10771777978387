// move to some utils file
export const getStartingDate = (date) => {
    const day = new Date(date);
    const yyyy = day.getFullYear();
    let mm = day.getMonth() + 1; // Months start at 0!
    let dd = day.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return {
        dd,
        mm,
        yyyy,
    }
};
